<template>
  <b-container class="mt-5">
    <b-modal ref="modal" id="modal" title="Sessiekeuze opgeven">
      <b-form-group label="Maak uw keuze" v-slot="{ ariaDescribedby }">
          <b-form @submit="onSubmit" inline ref="form">
            <b-form-radio-group
                id="radio-group-1"
                v-model="form.classroom"
                :options="classrooms"
                :aria-describedby="ariaDescribedby"
                name="classroom"
                stacked
            ></b-form-radio-group>
          </b-form>
        <b-alert class="mt-3" variant="danger" v-show="error" show>Gelieve een sessie te kiezen</b-alert>
        <b-alert class="mt-3" variant="success" v-show="success" show>Uw keuze werd succesvol verzonden</b-alert>
        </b-form-group>
        <template #modal-footer>
          <b-button
                variant="danger"
                size="sm"
                @click="$bvModal.hide('modal')"
            >
              Sluiten
            </b-button>&nbsp;
            <b-button
                variant="primary"
                size="sm"
                type="submit"
                @click="onSubmit($event)"
            >
              Bevestigen
            </b-button>
        </template>
    </b-modal>
    <b-row class="mt-5">
      <b-col>
        <h3>Procedure afwijkende toelatingsvoorwaarden</h3>
        <b-alert variant="warning" :show="totalSubscriptions === 0">Je hebt nog geen inschrijvingen voor afwijkende toelatingsvoorwaarden.</b-alert>
        <b-table v-if="totalSubscriptions > 0" :items="subscriptions" :fields="subscriptionFields" class="mt-3">
          <template v-slot:cell(title)="row">
            {{ row.item._embedded[0].title }}
          </template>
          <template v-slot:cell(status)="row">
            <b-badge variant="warning" v-if="row.item.status === 1">Nieuw</b-badge>
            <b-badge variant="success" v-if="row.item.status === 2">Toelating tot hoger onderwijs</b-badge>
            <b-badge variant="danger" v-if="row.item.status === 3">Geen toelating tot hoger onderwijs</b-badge>
            <b-badge variant="danger" v-if="row.item.status === 4">Geen 21 jaar</b-badge>
            <b-badge variant="danger" v-if="row.item.status === 5">Geen SOD</b-badge>
          </template>
          <template v-slot:cell(date)="row">
            {{  formatDate(row.item.subscription_date.date) }}
          </template>
          <template v-slot:cell(sod)="row">
            <b-badge variant="danger" v-if="row.item.sod !== true">NOK</b-badge>
            <b-badge variant="success" v-if="row.item.sod === true">OK</b-badge>
          </template>
          <template v-slot:cell(testsession)="row">
            <b-button variant="primary" @click="showModal(row.item._embedded[0].classrooms, row.item.subscriptionId)" size="xs" v-if="row.item.pat_classrooms_id === null && row.item.classroom_invite !== null">Sessiekeuze opgeven</b-button>
            <span v-for="classroom in row.item._embedded[0].classrooms" :key="classroom.id">
              <span v-if="row.item.pat_classrooms_id === classroom.id">{{ classroom.title + ' om ' + classroom.timestamp + ' te ' + classroom.location }}</span>
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <a class="btn btn-primary btn-xs" :href="'/subscription/' + row.item._embedded[0].id">Detail</a>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from '@/axios/api.instance'
import {mapGetters} from "vuex";
import Mailer from "../../../mixins/Mailer";

export default {
  mixins: [Mailer],
  name: "Dashboard",
  data () {
    return {
      form: {
        classroom: ''
      },
      selectedSubscription: null,
      error: false,
      success: false,
      infoSessions: [],
      totalSessions: 1,
      subscriptions: [],
      classrooms: [],
      totalSubscriptions: 1,
      sessionFields: [
        { key: 'title', label: 'Sessie' },
        { key: 'introduction', label: 'Info' },
        { key: 'date', label: 'Datum ingeschreven' }
      ],
      subscriptionFields: [
        { key: 'title', label: 'Procedure' },
        { key: 'status', label: 'Status' },
        { key: 'date', label: 'Datum ingeschreven' },
        { key: 'sod', label: 'SOD' },
        { key: 'testsession', label: 'Testsessie' },
        { key: 'actions', label: 'Acties' }
      ]
    }
  },
  computed: {
    ...mapGetters([
        'userInfo'
    ])
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      if (this.form.classroom === '') {
        this.success = false
        this.error = true
      } else {
        Api.put('/subscriptions/' + this.selectedSubscription, {classrooms_id: this.form.classroom}).then(() => {
          this.success = true
          this.error = false

          Api.get('/mailing/emails/' + process.env.VUE_APP_STUDENT_SESSION_EMAIL).then(async response => {
            let testDate = null;
            let selectedClassroom = {}
            console.log(this.subscriptions, this.selectedSubscription)
            this.subscriptions.forEach((subscription) => {
              if (subscription.subscriptionId === this.selectedSubscription) {
                testDate = subscription._embedded[0].test_date_formatted
                subscription._embedded[0].classrooms.forEach((classroom) => {
                  if (classroom.id === this.form.classroom) {
                    selectedClassroom = classroom
                  }
                })
              }
            })

            const registerReplacers = [
              {
                needle: 'student',
                replacer: this.userInfo.firstName + ' ' + this.userInfo.lastName
              },
              {
                needle: 'testDate',
                replacer: testDate
              },
              {
                needle: 'testTime',
                replacer: selectedClassroom.timestamp
              },
              {
                needle: 'testTime2',
                replacer: selectedClassroom.timestamp
              },
              {
                needle: 'location',
                replacer: selectedClassroom.location
              },
              {
                needle: 'mapLink',
                replacer: selectedClassroom.map_link
              }
            ]
            const registerEmail = await this.composeEmail(registerReplacers, response.data.content)
            await this.sendMail(this.userInfo.email, this.userInfo.firstName + ' ' + this.userInfo.lastName, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, response.data.subject, registerEmail.body)
          }).catch(err => {
            console.log(err)
          })

          Api.get('/subscriptions?uuid=' + this.$store.getters.uuid).then(response => {
            this.subscriptions = response.data._embedded.subscriptions
            this.totalSubscriptions = response.data.total_items
          }).catch(err => {
            console.log(err)
          })

          setTimeout(() => {
            this.$refs['modal'].hide()
          }, 3000)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    formatDate (dateValue) {
      const date = new Date(dateValue.slice(0, 10))
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    },
    showModal (classrooms, subscriptionId) {
      this.error = false
      this.success = false
      this.classrooms = []
      this.selectedSubscription = subscriptionId
      classrooms.forEach ((value) => {
        this.classrooms.push({
          text: value.title + ' om ' + value.timestamp,
          value: value.id
        })
      })
      this.$refs['modal'].show()
    }
  },
  beforeMount() {
    Api.get('/info-sessions?uuid=' + this.$store.getters.uuid).then(response => {
      this.infoSessions = response.data._embedded.info_sessions
      this.totalSessions = response.data.total_items
    }).catch(err => {
      console.log(err)
    })

    Api.get('/subscriptions?uuid=' + this.$store.getters.uuid).then(response => {
      this.subscriptions = response.data._embedded.subscriptions
      this.totalSubscriptions = response.data.total_items
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
