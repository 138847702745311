<template>
  <b-container class="mt-5">
    <b-row>
      <b-col md="4" offset-md="4">
        <b-form @submit="onSubmit" @reset="onReset" role="form">
          <b-form-group
          >
            <b-form-input
                id="current_password"
                type="password"
                v-model="form.current_password"
                placeholder="Huidig wachtwoord"
                :state="formValidation.current_password"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.current_password">
              het wachtwoord is niet juist
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="mt-3"
          >
            <b-form-input
                id="password"
                type="password"
                v-model="form.password"
                placeholder="Nieuw wachtwoord"
                :state="formValidation.password"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.password">
              Ongeldig wachtwoord
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="mt-3"
          >
            <b-form-input
                placeholder="Wachtwoord herhalen"
                id="confirm_password"
                type="password"
                v-model="form.confirm_password"
                :state="formValidation.confirm_password"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.confirm_password">
              De wachtwoorden komen niet overeen
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="mt-3">
            <b-button type="submit" class="mr-5" variant="primary">Opslaan</b-button>
            <b-button type="reset" variant="danger">Annuleren</b-button>
          </div>


          <transition name="fade">
            <b-row v-if="showError" class="mt-3">
              <b-col>
                <b-alert variant="danger" show>Er is een fout opgetreden, probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>

          <transition name="fade">
            <b-row v-if="showSuccess" class="mt-3">
              <b-col>
                <b-alert variant="success" show>Uw wachtwoord is succesvol aangepast.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@/axios/api.instance'
import Gateway from '@/axios/gateway.instance'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: 'Password',
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      showSuccess: false,
      showError: false,
      user: {},
      form: {
        current_password: '',
        password: '',
        confirm_password: ''
      },
      formValidation: {
        current_password: null,
        password: null,
        confirm_password: null
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.showSuccess = false
      this.resetErrors()

      this.formValidation.current_password = this.form.confirm_password !== ''
      this.formValidation.password = this.form.password !== ''
      this.formValidation.confirm_password = this.form.confirm_password === this.form.password

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        Gateway.post('/oauth', {
          username: this.$store.getters.email,
          password: this.form.current_password,
          grant_type: 'password'
        }).then(response => {
          authToken.store(response.data, true)
          Api.get('/local/users?uuid=' + this.$store.getters.uuid).then(response => {
            Api.put('/local/users/' + response.data.id, {
              password: this.form.password
            }).then(() => {
              this.showSuccess = true

              setTimeout(() => {
                this.showSuccess = false
                this.onReset()
              }, 5000)
            }).catch(err => {
              this.showError = true
              if (err.response.data.validation_messages.email.length > 0) {
                this.formValidation.emailExist = false
              } else {
                this.formValidation.emailExist = true
              }
            })
          }).catch(err => {
            console.log(err)
          })
        }).catch(err => {
          console.log(err)
          this.formValidation.current_password = false
        })
      }
    },
    onReset (event) {
      if (event !== undefined) {
        event.preventDefault()
      }
      this.form.current_password = ''
      this.form.password = ''
      this.form.confirm_password = ''
      this.resetErrors()
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>
.mr-5 {
  margin-right:20px;
}
</style>
