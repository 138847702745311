<template>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <b-alert variant="warning" :show="totalSessions === 0">Er zijn momenteel geen actieve infosessies waarvoor je kan inschrijven.</b-alert>
      </b-col>
    </b-row>
    <b-row v-for="(session, key) in infoSessions" :key="key">
      <b-col cols="12">
        <h1>{{ session.title }}</h1>
        <div v-html="session.introduction"></div>
      </b-col>
      <InfoSessionForm :id="session.id" :email-body="session.email_body" :email-subject="session.email_subject" class="mb-5"></InfoSessionForm>
    </b-row>
  </b-container>
</template>

<script>
import Api from '@/axios/api.instance'
import InfoSessionForm from "../../../components/InfoSessionForm";

export default {
  name: "InfoSessions",
  components: {InfoSessionForm},
  data () {
    return {
      infoSessions: [],
      totalSessions: 1
    }
  },
  beforeMount() {
    Api.get('/info-sessions?active=1').then(response => {
      this.infoSessions = response.data._embedded.info_sessions
      this.totalSessions = response.data.total_items
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
