<template>
  <div></div>
</template>

<script>
export default {
name: "Home",
  beforeMount() {
    this.$router.push('/account/login')
  }
}
</script>

<style scoped>

</style>
