import Confirm from "../views/modules/account/Confirm";
import Login from "../views/modules/account/Login";
import Account from "../views/modules/account/Account";
import Dashboard from "../views/modules/account/Dashboard";
import ForgotPassword from "../views/modules/account/ForgotPassword";
import Logout from "../views/modules/account/Logout";
import Information from "../views/modules/account/Information";
import Password from "../views/modules/account/Password";

const routes = {
    path: '/account/',
    component: Account,
    meta: {
        pageTitle: 'Account'
    },
    children: [
        {
            path: 'confirm/:id',
            name: 'AccountConfirm',
            component: Confirm,
            meta: {
                pageTitle: 'Account bevestigen'
            },
        },
        {
            path: 'login',
            name: 'AccountLogin',
            component: Login,
            meta: {
                pageTitle: 'Login'
            },
        },
        {
            path: 'dashboard',
            name: 'AccountDashboard',
            component: Dashboard,
            meta: {
                pageTitle: 'Dashboard',
                isAuthenticated: true,
            },
        },
        {
            path: 'information',
            name: 'AccountInformation',
            component: Information,
            meta: {
                pageTitle: 'Gegevens',
                isAuthenticated: true,
            },
        },
        {
            path: 'password',
            name: 'AccountPassword',
            component: Password,
            meta: {
                pageTitle: 'Wachtwoord bewerken',
                isAuthenticated: true,
            },
        },
        {
            path: 'forgot-password',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                pageTitle: 'Wachtwoord vergeten'
            },
        },
        {
            path: 'logout',
            name: 'Logout',
            component: Logout,
            meta: {
                pageTitle: 'Afmelden'
            },
        }
    ]
}

export default routes
