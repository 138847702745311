<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: 'Logout',
  beforeMount () {
    this.clear()
    authToken.clear(true)

    this.$router.push('/account/login')
  },
  methods: {
    ...mapActions([
      'clear'
    ])
  }
}
</script>

<style scoped>

</style>
