<template>
  <b-row>
    <b-col class="text-center mt-5">
      <h1>404</h1>
      <h3>Pagina niet gevonden</h3>

      <div class="error-desc">
        Sorry, de pagina die je hebt opgevraagd werd niet gevonden.
        <div class="form-group mt-3">
          <a href="https://www.auha.be" class="btn btn-primary">Terug naar begin</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>
