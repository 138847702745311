<template>
  <div>
    <div id="top-header">
      &nbsp;
    </div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-navbar toggleable="lg">
            <b-navbar-brand href="https://www.auha.be">
              <img :src="logo" width="250" />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ms-auto">
                <b-nav-item href="/account/login" v-if="userInfo.uuid === null">Mijn AUHA</b-nav-item>
                <b-nav-item-dropdown v-if="userInfo.uuid !== null" text="Mijn AUHA">
                  <b-dropdown-item href="/account/dashboard">Dashboard</b-dropdown-item>
                  <b-dropdown-item href="/account/information">Gegevens</b-dropdown-item>
                  <b-dropdown-item href="/account/password">Wachtwoord</b-dropdown-item>
                  <b-dropdown-item href="/account/logout">Afmelden</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
    <div id="image">
      <b-container>
        <b-row>
          <b-col>
            <h1>{{ this.$route.meta.pageTitle }}</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: "Navbar",
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data: () => {
    return {
      logo: require('./../assets/auha_logo.jpg')
    }
  }
}
</script>

<style scoped>
  h1 {
    color:#FFFFFF;
    font-size:46px;
    font-weight:500;
    margin-top:60px;
    font-family: "Open Sans", Arial, sans-serif;
  }
  #image {
    background-image: url("../assets/overauha.jpg");
    background-size:cover;
    background-position-x: 50%;
    background-position-y: 50%;
    height:176px;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #124166;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    outline:none;
    box-shadow: none;
  }

  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:focus {
    outline:none;
    box-shadow: none;
    opacity: .7;
    color: #124166;
  }

  .navbar-light .navbar-nav .nav-link:visited {
    outline:none;
    box-shadow: none;
  }

  #top-header {
    height:44px;
    background: #f4f4f4;
  }

  .navbar-light .navbar-toggler {
    color: #940639 !important;
    border:none;
  }
</style>
