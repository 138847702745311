import Api from '@/axios/api.instance'

export default {
  methods: {
    composeEmail: function (replacers, body) {
      return new Promise((resolve) => {
            let emailContent = body
            if (replacers.length > 0) {
              replacers.forEach((value) => {
                emailContent = emailContent.replace('{%' + value.needle + '%}', value.replacer)
              })
            }

          const template = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n' +
            '<html>\n' +
            '<head>\n' +
            '    <meta name="viewport" content="width=device-width" />\n' +
            '\n' +
            '    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />\n' +
            '    <title>Baan Bee - email</title>\n' +
            '\n' +
            '\n' +
            '    <style type="text/css">\n' +
            '        html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th{\n' +
            '            margin: 0;\n' +
            '            padding: 0;\n' +
            '            outline: 0;\n' +
            '            font-size: 100%;\n' +
            '            vertical-align: baseline;\n' +
            '            background: transparent;\n' +
            '        }\n' +
            '\n' +
            '        body {\n' +
            '            background-color:#ffffff;\n' +
            '            font-family:Arial;\n' +
            '            font-size:12px;\n' +
            '            line-height:17px;\n' +
            '            color:#444444;\n' +
            '            width:100%;\n' +
            '        }\n' +
            '\n' +
            '        img {\n' +
            '            max-width:100%;\n' +
            '        }\n' +
            '\n' +
            '        table.body-wrap{width:100%;}\n' +
            '\n' +
            '        .container {\n' +
            '            display:block!important;\n' +
            '            max-width:526px!important;\n' +
            '            margin:0 auto!important; /* makes it centered */\n' +
            '            clear:both!important;\n' +
            '        }\n' +
            '\n' +
            '        .contentBox{border:5px solid #B10059;border-top:5px solid #B10059;padding:30px;}\n' +
            '        .contentBox p{};\n' +
            '\n' +
            '\n' +
            '    </style>\n' +
            '</head>\n' +
            '\n' +
            '<body topmargin="0" leftmargin="0" marginheight="0" marginwidth="0">\n' +
            '<table class="body-wrap" align="center">\n' +
            '    <tr>\n' +
            '        <td></td>\n' +
            '        <td class="container">\n' +
            '            <img src="' + process.env.VUE_APP_IMAGE_URL + '/img/auha_logo.jpg" width="250" style="margin-bottom:30px;" />\n' +
            '            <div class="contentBox">\n' +
            '                ' + emailContent + '\n' +
            '            </div>\n' +
            '\n' +
            '        </td>\n' +
            '        <td></td>\n' +
            '    </tr>\n' +
            '</table>\n' +
            '</body>\n' +
            '</html>'

            resolve({
              body: template
            })
      })
    },
    sendMail: function (toAddress, toName, fromAddress, fromName, subject, body) {
      return new Promise((resolve) => {
        Api.post('/mailing/send', {
          from: fromAddress,
          from_name: fromName,
          to: toAddress,
          to_name: toName,
          subject: subject,
          body: body
        }).then(() => {
          resolve(true)
        }).catch(err => {
          resolve(err)
        })
      })
    }
  }
}
