<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" inline>
      <b-row>
        <b-col>
          <b-form-group
              id="firstName"
              label-for="firstName"
          >
            <b-form-input
                id="firstName"
                v-model="form.firstName"
                type="text"
                placeholder="Voornaam *"
                :state="validation.firstName"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.firstName">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="name"
              label-for="name"
          >
            <b-form-input
                id="name"
                v-model="form.name"
                type="text"
                placeholder="Naam *"
                :state="validation.name"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.name">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="email"
              label-for="email"
          >
            <b-form-input
                id="email"
                v-model="form.email"
                type="text"
                placeholder="Email *"
                :state="validation.email"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.email">
              Ongeldige email
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="birthDate"
              label-for="birthDate"
          >
            <b-form-datepicker
                id="birthDate"
                v-model="form.birthDate"
                placeholder="Geboortedatum *"
                :state="validation.birthDate"
            ></b-form-datepicker>
            <b-form-invalid-feedback :state="validation.birthDate">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="birth_place"
              label-for="birth_place"
          >
            <b-form-input
                id="birth_place"
                v-model="form.birthPlace"
                type="text"
                placeholder="Geboorteplaats *"
                :state="validation.birthPlace"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.birthPlace">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="birthCountry"
              label-for="birthCountry"
          >
            <b-form-input
                id="birthCountry"
                v-model="form.birthCountry"
                placeholder="Geboorteland *"
                :state="validation.birthCountry"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.birthCountry">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="origin"
              label-for="origin"
          >
            <b-form-input
                id="origin"
                v-model="form.origin"
                type="text"
                placeholder="Nationaliteit *"
                :state="validation.origin"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.origin">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="gender"
              label-for="gender"
              label="Geslacht *"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
                id="gender"
                v-model="form.gender"
                :options="genderOptions"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
            ></b-form-radio-group>
            <b-form-invalid-feedback :state="validation.gender">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="street"
              label-for="street"
          >
            <b-form-input
                id="street"
                v-model="form.street"
                type="text"
                placeholder="Straat *"
                :state="validation.street"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.street">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="number"
              label-for="number"
          >
            <b-form-input
                id="number"
                v-model="form.number"
                type="text"
                placeholder="Nummer *"
                :state="validation.number"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.number">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="postalCode"
              label-for="postalCode"
          >
            <b-form-input
                id="postalCode"
                v-model="form.postalCode"
                type="text"
                placeholder="Postcode *"
                :state="validation.postalCode"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.postalCode">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="town"
              label-for="town"
          >
            <b-form-input
                id="town"
                v-model="form.town"
                type="text"
                placeholder="Gemeente *"
                :state="validation.town"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.town">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="country"
              label-for="country"
          >
            <b-form-input
                id="country"
                v-model="form.country"
                type="text"
                placeholder="Land *"
                :state="validation.country"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.country">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="phone"
              label-for="phone"
          >
            <b-form-input
                id="phone"
                v-model="form.phone"
                type="text"
                placeholder="Telefoon"
                :state="validation.phone"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.phone">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="idNumber"
              label-for="idNumber"
          >
            <b-form-input
                id="idNumber"
                v-model="form.idNumber"
                type="text"
                placeholder="Rijksregisternummer"
                :state="validation.idNumber"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.idNumber">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <hr /><br />
          <p>Schrijft zich in voor de procedure afwijkende toelatingsvoorwaarden.</p>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="futureEducation"
              label-for="futureEducation"
          >
            <b-form-input
                id="futureEducation"
                v-model="form.futureEducation"
                type="text"
                placeholder="Toekomstige opleiding *"
                :state="validation.futureEducation"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.futureEducation">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="institution"
              label-for="institution"
          >
            <b-form-select
                id="institution"
                v-model="form.institution"
                type="text"
                class="form-control custom-select"
                placeholder="Aan instelling *"
                :options="institutionOptions"
                :state="validation.institution"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>-- Aan instelling * --</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="validation.institution">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <hr />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group :label="'Ik zal op 31 december ' + currentYear + ' minimum 21 jaar oud zijn'" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                id="age"
                v-model="form.age"
                :options="ageOptions"
                :aria-describedby="ariaDescribedby"
                name="age"
            ></b-form-radio-group>
            <b-form-invalid-feedback :state="validation.age">
              Verplicht aan te vinken
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-col>
            <b-form-checkbox
                id="privacy"
                v-model="form.privacy"
                type="text"
                name="privacy"
                unchecked-value="not_accepted"
                value="accepted"
            >
              &nbsp;Ik accepteer het <a href="https://www.auha.be/privacybeleid/" target="_blank">privacybeleid</a> om mijn gegevens te laten verwerken door AUHA
            </b-form-checkbox>
          </b-col>
        </b-col>
      </b-row>

      <transition name="fade">
        <b-row v-if="showError" class="mt-3">
          <b-col>
            <b-alert variant="danger" show>Er is een serverfout opgetreden bij het verzenden van de gegevens. Probeer opnieuw.</b-alert>
          </b-col>
        </b-row>
      </transition>

      <transition name="fade">
        <b-row v-if="emailError" class="mt-3">
          <b-col>
            <b-alert variant="danger" show>Het opgegeven emailadres bestaat al in ons systeem en is gekoppeld aan een account. Gelieve eerst in te loggen en dan uw aanvraag in te dienen. <a href="/account/login">Nu inloggen</a></b-alert>
          </b-col>
        </b-row>
      </transition>

      <transition name="fade">
        <b-row v-if="showSuccess" class="mt-3">
          <b-col>
            <b-alert variant="success" show>Je bent succesvol aangemeld voor de procedure. Je ontvangt dadelijk een email ter bevestiging. <strong>Heb je na 5 minuten nog geen email ontvangen, kijk dan even bij uw ongewenste emails.</strong></b-alert>
          </b-col>
        </b-row>
      </transition>

      <transition name="fade">
        <b-row v-if="showRegisterSuccess" class="mt-3">
          <b-col>
            <b-alert variant="success" show>Je bent succesvol aangemeld voor de procedure. Je ontvangt dadelijk een email met verdere instructies om uw account te activeren. <strong>Heb je na 5 minuten nog geen email ontvangen, kijk dan even bij uw ongewenste emails.</strong></b-alert>
          </b-col>
        </b-row>
      </transition>

      <b-row class="mt-3">
        <b-col>
          <b-button type="submit" variant="primary">Indienen</b-button>
          <b-button class="m-3" type="reset" variant="danger">Annuleren</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <p class="f-10">Kandidaten kunnen <strong>slechts één maal deelnemen aan een toelatingsonderzoek</strong> met het oog op inschrijving in een welbepaald academiejaar.
          Indien je niet slaagt voor het toelatingsonderzoek voor dat academiejaar, kan je pas herkansen voor het toelatingsonderzoek met het oog op een volgend academiejaar.
          Indien vastgesteld wordt dat de test toch een tweede keer voor eenzelfde academiejaar werd afgelegd, is de tweede uitslag ongeldig.
          Deze beperking op herkansing geldt voor de Procedure Afwijkende Toelating aan alle vijf Vlaamse associaties en hun instellingen.
          De Associatie Universiteit & Hogescholen Antwerpen (AUHA) stemt de aanmeldingen dus af met de andere associaties, maar heeft hiervoor uw toestemming nodig.
          “Ik verleen hierbij mijn toestemming aan de AUHA om mijn persoonsgegevens die in het kader van dit bekwaamheidsonderzoek verkregen zijn uit te wisselen met de andere associaties in Vlaanderen.”
        </p>
      </b-row>
    </b-form>
  </div>
</template>

<script>

import Api from '@/axios/api.instance'
import Mailer from "../mixins/Mailer"
import Jwt from 'jwt-simple'

export default {
  mixins: [Mailer],
  props: {
    id: {
      required: true,
      type: Number
    },
    emailBody: {
      required: true,
      type: String
    },
    emailSubject: {
      required: true,
      type: String
    }
  },
  name: "SubscriptionForm",
  data () {
    return {
      currentYear: new Date().getFullYear(),
      genderOptions: [
        { text: 'Man', value: 'Man' },
        { text: 'Vrouw', value: 'Vrouw' },
        { text: 'Genderneutraal', value: 'Genderneutraal' }
      ],
      ageOptions: [
        { text: 'Ja', value: true },
        { text: 'Nee', value: 0 }
      ],
      institutionOptions: [
        { text: 'AP Hogeschool', value: 'AP Hogeschool' },
        { text: 'Hogere Zeevaartschool', value: 'Hogere Zeevaartschool' },
        { text: 'Karel de Grote Hogeschool', value: 'Karel de Grote Hogeschool' },
        { text: 'Universiteit Antwerpen', value: 'Universiteit Antwerpen' }
      ],
      showSuccess: false,
      showError: false,
      emailError: false,
      showRegisterSuccess: false,
      form: {
        firstName: this.$store.getters.firstName,
        name: this.$store.getters.lastName,
        email: this.$store.getters.email,
        birthDate: '',
        birthPlace: '',
        birthCountry: '',
        origin: '',
        gender: '',
        street: '',
        number: '',
        postalCode: '',
        town: '',
        country: '',
        phone: '',
        idNumber: '',
        futureEducation: '',
        institution: null,
        age: null,
        privacy: 'not_accepted'
      },
      validation: {
        firstName: null,
        name: null,
        email: null,
        birthDate: null,
        birthPlace: null,
        birthCountry: null,
        origin: null,
        gender: null,
        street: null,
        number: null,
        postalCode: null,
        town: null,
        country: null,
        phone: null,
        idNumber: null,
        futureEducation: null,
        institution: null,
        age: null,
        privacy: null
      }
    }
  },
  beforeMount() {
    const subscriptionForm = localStorage.getItem('subscriptionForm')

    if (subscriptionForm !== null && subscriptionForm !== undefined) {
      this.form = JSON.parse(subscriptionForm)
    }
  },
  methods: {
    onReset () {
      this.form.firstName = ''
      this.form.name = ''
      this.form.email = ''
      this.form.birthDate = ''
      this.form.birthPlace = ''
      this.form.birthCountry = ''
      this.form.origin = ''
      this.form.gender = ''
      this.form.street = ''
      this.form.number = ''
      this.form.postalCode = ''
      this.form.town = ''
      this.form.country = ''
      this.form.phone = ''
      this.form.idNumber = ''
      this.form.futureEducation = ''
      this.form.institution = ''
      this.form.age = ''
      this.form.privacy = 'not_accepted'
      this.showError = false
      this.showRegisterSuccess = false
      this.showSuccess = false
      this.emailError = false
      this.resetErrors()
    },
    async onSubmit (event) {
      event.preventDefault()

      this.showSuccess = false
      this.showRegisterSuccess = false
      this.resetErrors()

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.validation.firstName = this.form.firstName !== ''
      this.validation.name = this.form.name !== ''
      this.validation.email = re.test(this.form.email)
      this.validation.birthDate = this.form.birthDate !== ''
      this.validation.birthPlace = this.form.birthPlace !== ''
      this.validation.birthCountry = this.form.birthCountry !== ''
      this.validation.origin = this.form.origin !== ''
      this.validation.gender = this.form.gender !== ''
      this.validation.street = this.form.street !== ''
      this.validation.number = this.form.number !== ''
      this.validation.postalCode = this.form.postalCode !== ''
      this.validation.town = this.form.town !== ''
      this.validation.country = this.form.country !== ''
      this.validation.futureEducation = this.form.futureEducation !== ''
      this.validation.institution = this.form.institution !== null
      this.validation.age = this.form.age !== null
      this.validation.privacy = this.form.privacy === 'accepted'

      const password = Math.random().toString(36).substring(2, 10)

      const validationValues = Object.values(this.validation)
      if (validationValues.indexOf(false) < 0) {
        if (this.$store.getters.uuid === null) {
          const userPayload = {
            first_name: this.form.firstName,
            last_name: this.form.name,
            email: this.form.email,
            password: password,
            user_roles_id: 3
          }

          Api.post('/local/users', userPayload).then(response => {
            const uuid = response.data.uuid
            this.submitSubscription(uuid)
            this.showRegisterSuccess = true

            const hash = Jwt.encode(
                {
                  firstName: this.form.firstName,
                  lastName: this.form.name,
                  email: this.form.email,
                  dateCreated: response.data.date_created,
                  uuid: response.data.uuid,
                  id: response.data.id
                }, process.env.VUE_APP_ACCOUNT_SECURITY_TOKEN, 'HS256'
            )

            Api.get('/mailing/emails/' + process.env.VUE_APP_STUDENT_REGISTER_EMAIL).then(async response => {


              const registerReplacers = [
                {
                  needle: 'fullName',
                  replacer: this.form.firstName + ' ' + this.form.name
                },
                {
                  needle: 'email',
                  replacer: this.form.email
                },
                {
                  needle: 'password',
                  replacer: password
                },
                {
                  needle: 'url',
                  replacer: process.env.VUE_APP_ACCOUNT_CONFIRM_URL + '/' + hash
                }
              ]
              const registerEmail = await this.composeEmail(registerReplacers, response.data.content)
              await this.sendMail(this.form.email, this.form.firstName + ' ' + this.form.name, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, response.data.subject, registerEmail.body)
            }).catch(err => {
              console.log(err)
            })

            setTimeout(() => {
              this.onReset()
            }, 10000)
          }).catch(err => {
            console.log(err)

            if (err.response.status === 422) {
              this.validation.email = false

              localStorage.setItem('subscriptionForm', JSON.stringify(this.form))

              this.emailError = true
            } else {
              this.showError = true
            }
          })
        } else {
          this.submitSubscription(this.$store.getters.uuid)
          this.showSuccess = true

          setTimeout(() => {
            this.onReset()
          }, 10000)
        }
      }
    },
    submitSubscription (uuid) {
      const sessionPayload = {
        first_name: this.form.firstName,
        last_name: this.form.name,
        email: this.form.email,
        birth_date: this.form.birthDate,
        pat_request_sessions_id: this.id,
        uuid: uuid,
        birth_place: this.form.birthPlace,
        birth_country: this.form.birthCountry,
        street: this.form.street,
        number: this.form.number,
        postal_code: this.form.postalCode,
        town: this.form.town,
        country: this.form.country,
        origin: this.form.origin,
        gender: this.form.gender,
        phone: this.form.phone,
        id_number: this.form.idNumber,
        future_education: this.form.futureEducation,
        institution: this.form.institution,
        age: this.form.age
      }
      Api.post('/subscriptions', sessionPayload).then(async (response) => {
        await localStorage.setItem('subscription', response.data._links.self.href.split('/').reverse()[0])

        const replacers = [
          {
            replacer: this.form.firstName + ' ' + this.form.name,
            needle: 'student'
          },
          {
            replacer: this.form.firstName,
            needle: 'voornaam'
          },
          {
            replacer: this.form.name,
            needle: 'naam'
          },
          {
            replacer: this.form.email,
            needle: 'email'
          },
        ]

        const email = await this.composeEmail(replacers, this.emailBody)
        await this.sendMail(this.form.email, this.form.firstName + ' ' + this.form.name, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, this.emailSubject, email.body)

        localStorage.removeItem('subscriptionForm')

        this.$emit('redirect', response.data._links.self.href.split('/').reverse()[0])
      }).catch(err => {
        console.log(err)
      })
    },
    resetErrors () {
      this.showError = false
      this.emailError = false
      for (const property in this.validation) {
        this.validation[property] = null
      }
    }
  }
}
</script>

<style scoped>
.f-10 {
  font-size: 12px;
}
</style>
