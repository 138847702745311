<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" inline>
      <b-row>
        <b-col>
          <b-form-group
              id="firstName"
              label-for="firstName"
          >
            <b-form-input
                id="firstName"
                v-model="form.firstName"
                type="text"
                placeholder="Voornaam *"
                :state="validation.firstName"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.firstName">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="name"
              label-for="name"
          >
            <b-form-input
                id="name"
                v-model="form.name"
                type="text"
                placeholder="Naam *"
                :state="validation.name"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.name">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="email"
              label-for="email"
          >
            <b-form-input
                id="email"
                v-model="form.email"
                type="text"
                placeholder="Email *"
                :state="validation.email"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.email">
              Ongeldige email
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="birthDate"
              label-for="birthDate"
          >
            <b-form-datepicker
                id="birthDate"
                v-model="form.birthDate"
                placeholder="Geboortedatum *"
                :state="validation.birthDate"
            ></b-form-datepicker>
            <b-form-invalid-feedback :state="validation.birthDate">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group
              id="futureEducation"
              label-for="futureEducation"
          >
            <b-form-input
                id="futureEducation"
                v-model="form.futureEducation"
                type="text"
                placeholder="Toekomstige opleiding *"
                :state="validation.futureEducation"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation.futureEducation">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              id="institution"
              label-for="institution"
          >
            <b-form-select
                id="institution"
                v-model="form.institution"
                type="text"
                class="form-control custom-select"
                placeholder="Aan instelling *"
                :options="institutionOptions"
                :state="validation.institution"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>-- Aan instelling * --</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="validation.institution">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-checkbox
              id="privacy"
              v-model="form.privacy"
              type="text"
              name="privacy"
              unchecked-value="not_accepted"
              value="accepted"
          >
            &nbsp;Ik accepteer het <a href="https://www.auha.be/privacybeleid/" target="_blank">privacybeleid</a> om mijn gegevens te laten verwerken door AUHA
          </b-form-checkbox>
          <b-form-invalid-feedback :state="validation.privacy">
            Verplicht aan te vinken
          </b-form-invalid-feedback>

          <b-form-checkbox
              id="account"
              name="account"
              v-model="form.account"
              type="text"
              unchecked-value="no"
              value="yes"
              v-if="this.$store.getters.uuid === null"
          >
            &nbsp;Ik zou graag een account laten aanmaken met mijn gegevens
          </b-form-checkbox>
        </b-col>
      </b-row>

      <transition name="fade">
        <b-row v-if="showError" class="mt-3">
          <b-col>
            <b-alert variant="danger" show>Er is een serverfout opgetreden bij het verzenden van de gegevens. Probeer opnieuw.</b-alert>
          </b-col>
        </b-row>
      </transition>

      <transition name="fade">
        <b-row v-if="emailError" class="mt-3">
          <b-col>
            <b-alert variant="danger" show>Het opgegeven emailadres bestaat al in ons systeem en is gekoppeld aan een account.</b-alert>
          </b-col>
        </b-row>
      </transition>

      <transition name="fade">
        <b-row v-if="showSuccess" class="mt-3">
          <b-col>
            <b-alert variant="success" show>Je bent succesvol ingeschreven voor de infosessie. Je ontvangt dadelijk een email ter bevestiging. <strong>Heb je na 5 minuten nog geen email ontvangen, kijk dan even bij uw ongewenste emails.</strong></b-alert>
          </b-col>
        </b-row>
      </transition>

      <transition name="fade">
        <b-row v-if="showRegisterSuccess" class="mt-3">
          <b-col>
            <b-alert variant="success" show>Je bent succesvol ingeschreven voor de infosessie. Je ontvangt dadelijk een email met verdere instructies om uw account te activeren. <strong>Heb je na 5 minuten nog geen email ontvangen, kijk dan even bij uw ongewenste emails.</strong></b-alert>
          </b-col>
        </b-row>
      </transition>

      <b-row class="mt-3">
        <b-col>
          <b-button type="submit" variant="primary">Inschrijven</b-button>
          <b-button class="m-3" type="reset" variant="danger">Annuleren</b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>

import Api from '@/axios/api.instance'
import Mailer from "../mixins/Mailer"
import Jwt from 'jwt-simple'

export default {
  mixins: [Mailer],
  props: {
    id: {
      required: true,
      type: Number
    },
    emailBody: {
      required: true,
      type: String
    },
    emailSubject: {
      required: true,
      type: String
    }
  },
  name: "InfoSessionForm",
  data () {
    return {
      showSuccess: false,
      showError: false,
      emailError: false,
      showRegisterSuccess: false,
      institutionOptions: [
        { text: 'AP Hogeschool', value: 'AP Hogeschool' },
        { text: 'Antwerp Maritime Academy', value: 'Antwerp Maritime Academy' },
        { text: 'Karel de Grote Hogeschool', value: 'Karel de Grote Hogeschool' },
        { text: 'Universiteit Antwerpen', value: 'Universiteit Antwerpen' }
      ],
      form: {
        firstName: this.$store.getters.firstName,
        name: this.$store.getters.lastName,
        email: this.$store.getters.email,
        birthDate: '',
        futureEducation: '',
        institution: null,
        privacy: 'not_accepted',
        account: 'no'
      },
      validation: {
        firstName: null,
        name: null,
        email: null,
        birthDate: null,
        futureEducation: null,
        institution: null,
        privacy: null,
        account: null
      }
    }
  },
  methods: {
    onReset () {
      this.form.firstName = ''
      this.form.name = ''
      this.form.email = ''
      this.form.birthDate = ''
      this.form.futureEducation = ''
      this.form.institution = null
      this.form.privacy = 'not_accepted'
      this.form.account = 'no'
      this.showError = false
      this.showRegisterSuccess = false
      this.showSuccess = false
      this.emailError = false
      this.resetErrors()
    },
    async onSubmit (event) {
      event.preventDefault()

      const replacers = [
        {
          replacer: this.form.firstName + ' ' + this.form.name,
          needle: 'student'
        },
        {
          replacer: this.form.firstName,
          needle: 'voornaam'
        },
        {
          replacer: this.form.name,
          needle: 'naam'
        },
        {
          replacer: this.form.email,
          needle: 'email'
        },
      ]

      this.showSuccess = false
      this.showRegisterSuccess = false
      this.resetErrors()

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.validation.firstName = this.form.firstName !== ''
      this.validation.name = this.form.name !== ''
      this.validation.email = re.test(this.form.email)
      this.validation.birthDate = this.form.birthDate !== ''
      this.validation.futureEducation = this.form.futureEducation !== ''
      this.validation.institution = this.form.institution !== ''
      this.validation.privacy = this.form.privacy === 'accepted'

      const password = Math.random().toString(36).substring(2, 10)

      const validationValues = Object.values(this.validation)
      if (validationValues.indexOf(false) < 0) {
        const email = await this.composeEmail(replacers, this.emailBody)
        await this.sendMail(this.form.email, this.form.firstName + ' ' + this.form.name, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, this.emailSubject, email.body)

        if (this.form.account === 'yes') {
          const userPayload = {
            first_name: this.form.firstName,
            last_name: this.form.name,
            email: this.form.email,
            password: password,
            user_roles_id: 3
          }

          Api.post('/local/users', userPayload).then(async response => {
            const uuid = response.data.uuid
            this.submitSubscription(uuid)
            this.showRegisterSuccess = true

            const hash = await Jwt.encode(
                {
                  firstName: this.form.firstName,
                  lastName: this.form.name,
                  email: this.form.email,
                  dateCreated: response.data.date_created,
                  uuid: response.data.uuid,
                  id: response.data.id
                }, process.env.VUE_APP_ACCOUNT_SECURITY_TOKEN, 'HS256'
            )

            Api.get('/mailing/emails/' + process.env.VUE_APP_STUDENT_REGISTER_EMAIL).then(async response => {


              const registerReplacers = [
                {
                  needle: 'fullName',
                  replacer: this.form.firstName + ' ' + this.form.name
                },
                {
                  needle: 'email',
                  replacer: this.form.email
                },
                {
                  needle: 'password',
                  replacer: password
                },
                {
                  needle: 'url',
                  replacer: process.env.VUE_APP_ACCOUNT_CONFIRM_URL + '/' + hash
                }
              ]
              const registerEmail = await this.composeEmail(registerReplacers, response.data.content)
              await this.sendMail(this.form.email, this.form.firstName + ' ' + this.form.name, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, response.data.subject, registerEmail.body)
            }).catch(err => {
              console.log(err)
            })

            setTimeout(() => {
              this.onReset()
            }, 10000)
          }).catch(err => {
            console.log(err)

            if (err.response.status === 422) {
              this.validation.email = false
              this.emailError = true
            } else {
              this.showError = true
            }
          })
        } else {
          this.submitSubscription(this.$store.getters.uuid)
          this.showSuccess = true

          setTimeout(() => {
            this.onReset()
          }, 10000)
        }
      }
    },
    submitSubscription (uuid) {
      const sessionPayload = {
        first_name: this.form.firstName,
        last_name: this.form.name,
        email: this.form.email,
        birth_date: this.form.birthDate,
        education: this.form.futureEducation,
        institution: this.form.institution,
        info_sessions_id: this.id,
        uuid: uuid
      }
      Api.post('/info-sessions', sessionPayload).then(() => {
        return
      }).catch(err => {
        console.log(err)
      })
    },
    resetErrors () {
      this.showError = false
      this.emailError = false
      for (const property in this.validation) {
        this.validation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
