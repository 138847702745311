<template>
  <b-container class="mt-5">
    <b-row v-if="Object.keys(subscription).length > 0 && subscription._embedded[0] !== undefined && subscription.uuid === this.$store.getters.uuid">
      <b-col>
        <h3>{{ subscription._embedded[0].title }}
        <b-badge variant="warning" v-if="subscription.status === 1">Nieuw</b-badge>
        <b-badge variant="success" v-if="subscription.status === 2">Toelating tot hoger onderwijs</b-badge>
        <b-badge variant="danger" v-if="subscription.status === 3">Geen toelating tot hoger onderwijs</b-badge>
        <b-badge variant="danger" v-if="subscription.status === 4">Geen 21 jaar</b-badge>
        <b-badge variant="danger" v-if="subscription.status === 5">Geen SOD</b-badge>
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <table class="table table-bordered">
          <tr>
            <td>
              Voornaam:
            </td>
            <td>{{ subscription.first_name }}</td>
          </tr>
          <tr>
            <td>
              Naam:
            </td>
            <td>{{ subscription.last_name }}</td>
          </tr>
          <tr>
            <td>
              Emailadres:
            </td>
            <td>{{ subscription.email }}</td>
          </tr>
          <tr>
            <td>
              Telefoon:
            </td>
            <td>{{ subscription.phone }}</td>
          </tr>
          <tr>
            <td>
              Gsm:
            </td>
            <td>{{ subscription.mobile }}</td>
          </tr>
          <tr>
            <td>
              Straat:
            </td>
            <td>{{ subscription.street }}</td>
          </tr>
          <tr>
            <td>
              Nummer:
            </td>
            <td>{{ subscription.number }}</td>
          </tr>
          <tr>
            <td>
              Postcode:
            </td>
            <td>{{ subscription.postal_code }}</td>
          </tr>
          <tr>
            <td>
              Gemeente:
            </td>
            <td>{{ subscription.town }}</td>
          </tr>
          <tr>
            <td>
              Land:
            </td>
            <td>{{ subscription.country }}</td>
          </tr>
          <tr v-if="subscription.pat_classrooms_id !== null && subscription._embedded[0] !== undefined">
            <td>Testsessie</td>
            <td>
              <span v-for="classroom in subscription._embedded[0].classrooms" :key="classroom.id">
                <span v-if="classroom.id === subscription.pat_classrooms_id">{{ classroom.title + ' om ' + classroom.timestamp + ' te ' + classroom.location }}</span>
                <span v-if="classroom.id === subscription.pat_classrooms_id && classroom.map_link !== '' && classroom.map_link !== null" class="float-end"><a :href="classroom.map_link" target="_blank" class="btn btn-xs btn-primary">Download map</a></span>
              </span>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col>
        <table class="table table-bordered">
          <tr>
            <td>
              Rijksregisternummer:
            </td>
            <td>{{ subscription.id_number }}</td>
          </tr>
          <tr>
            <td>
              Geboortedatum:
            </td>
            <td>{{ (subscription.birth_date !== undefined) ? formatDate(subscription.birth_date.date) : null }}</td>
          </tr>
          <tr>
            <td>
              Geboorteplaats:
            </td>
            <td>{{ subscription.birth_place }}</td>
          </tr>
          <tr>
            <td>
              Geboorteland:
            </td>
            <td>{{ subscription.birth_country }}</td>
          </tr>
          <tr>
            <td>
              Nationaliteit:
            </td>
            <td>{{ subscription.origin }}</td>
          </tr>
          <tr>
            <td>
              Geslacht:
            </td>
            <td>{{ subscription.gender }}</td>
          </tr>
          <tr>
            <td>
              21 op 31/12/{{ (subscription.subscription_date !== undefined) ? new Date(subscription.subscription_date.date.slice(0, 10)).getFullYear() : null }}:
            </td>
            <td>{{ subscription.age === true ? 'Ja' : 'Nee' }}</td>
          </tr>
          <tr>
            <td>
              Toekomstige opleiding:
            </td>
            <td>{{ subscription.future_education }}</td>
          </tr>
          <tr>
            <td>
              Instelling:
            </td>
            <td>{{ subscription.institution }}</td>
          </tr>
          <tr>
            <td>
              Ingeschreven:
            </td>
            <td>{{ (subscription.birth_date !== undefined) ? formatDate(subscription.subscription_date.date) : null }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <h3>Bestanden</h3>
        <table class="table table-bordered mb-5">
          <tr v-for="file in files" :key="file.file">
            <td><a :href="file.file" target="_blank">{{ file.file.split('/').reverse()[0] }}</a></td>
          </tr>
          <tr v-if="subscription.decision_file !== '' && subscription.decision_file !== null">
            <td>
              <span><a :href="subscription.decision_file" target="_blank" class="btn btn-xs btn-primary">Download besluit</a></span>
            </td>
          </tr>
        </table>
        <b-form @submit="onSubmit" class="form-horizontal" role="form" v-if="(subscription.status === 1 || subscription.status === 5) && subscription.sod !== true">
          <b-form-file v-model="form.file" id="file-large" accept=".jpg, .png, .gif, .jpeg, .docx, .doc, .xls, .xlsx, .pdf" size="lg" plain></b-form-file><br /><br/>
          <b-button type="submit" variant="primary">Opladen</b-button>
          <transition name="fade">
            <b-row v-if="error">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col>
                <b-alert variant="danger" show>Het bestand is niet goed. Enkel bestanden van maximum 10MB toegelaten.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
        <hr />
      </b-col>
    </b-row>
    <b-row v-if="subscription.external_message !== '' && subscription.external_message !== null" class="mt-3">
      <b-col>
        <h3>Bericht</h3>
        <p>{{ subscription.external_message }}</p>
        <hr />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <a class="btn btn-primary" href="/account/dashboard">Terug</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import Api from '@/axios/api.instance'

export default {
  name: "Subscription",
  data () {
    return {
      error: false,
      form: {
        file: []
      },
      subscriptionId: null,
      subscription: {},
      files: []
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      this.error = false

      const formData = new FormData()
      formData.append('file', this.form.file)
      formData.append('subscriptions_id', this.subscriptionId)

      Api.post('/files', formData).then(() => {
        this.form.file = []
        Api.get('/files/' + this.subscriptionId).then(response => {
          this.files = response.data.files
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        this.error = true
        console.log(err)
      })
    },
    formatDate (dateValue) {
      const date = new Date(dateValue.slice(0, 10))
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }
  },
  beforeMount() {
    Api.get('/subscriptions?uuid=' + this.$store.getters.uuid).then(response => {
      response.data._embedded.subscriptions.forEach((subscription) => {
        if (subscription._embedded[0].id === parseInt(this.$route.params.id)) {
          this.subscription = subscription
          this.subscriptionId = this.subscription.subscriptionId
        }

        Api.get('/files/' + this.subscription.subscriptionId).then(response => {
          this.files = response.data.files
        }).catch(err => {
          console.log(err)
        })
      })
    }).catch(err => {
      console.log(err)
    })


  }
}
</script>

<style scoped>
td {
  padding:5px 0px;
}

.badge {
  float:right;
}
</style>
