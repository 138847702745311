<template>
  <b-row>
    <b-col class="text-center mt-5">
      <h1>401</h1>
      <h3>Geen toegang</h3>

      <div class="error-desc">
        Sorry, het lijkt dat je geen toegang hebt tot deze pagina.
        <div class="form-group mt-3">
          <a href="https://www.auha.be" class="btn btn-primary">Terug naar begin</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Unauthorized'
}
</script>

<style scoped>

</style>
