<template>
  <b-container class="mt-5">
    <b-row>
      <b-col md="4" offset-md="4">
        <b-form @submit="onSubmit" inline>
          <b-form-group
              id="email"
              label-for="email"
          >
            <b-form-input
                id="email"
                v-model="form.email"
                type="text"
                placeholder="Uw emailadres"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="password-group"
              class="mt-3"
          >
            <b-form-input
                id="password"
                v-model="form.password"
                type="password"
                required
                placeholder="Uw wachtwoord"
            ></b-form-input>
          </b-form-group>
          <b-button block variant="primary" type="submit" class="mt-3">Login</b-button><br />
          <router-link to="/account/forgot-password" class="text-center btn-block link"><small>Wachtwoord vergeten?</small></router-link>
          <b-alert :show="showError" variant="danger" class="mt-2">De logingegevens zijn niet correct, probeer opnieuw.</b-alert>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import Api from '@/axios/gateway.instance'
import { mapActions } from 'vuex'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      showError: false
    }
  },
  methods: {
    ...mapActions([
      'fetch'
    ]),
    async onSubmit(evt) {
      evt.preventDefault()
      this.showError = false
      await Api.post('oauth', {
        grant_type: 'password',
        username: this.form.email,
        password: this.form.password
      }).then(async (data) => {
        this.showError = false
        authToken.store(data.data, true)
        await this.fetch

        const subscriptionForm = localStorage.getItem('subscriptionForm')

        if (subscriptionForm !== undefined && subscriptionForm !== null) {
          await this.$router.push('/subscriptions')
        } else {
          await this.$router.push('/account/dashboard')
        }
      }).catch((error) => {
        this.showError = true
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
