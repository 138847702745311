<template>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <b-alert variant="warning" :show="totalSubscriptions === 0">Er zijn momenteel geen actieve procedures waarvoor je kan aanmelden.</b-alert>
      </b-col>
    </b-row>
    <b-row v-for="(subscription, key) in subscriptions" :key="key">
      <b-col cols="12">
        <h1>{{ subscription.title }}</h1>
        <div v-html="subscription.introduction"></div>
      </b-col>
      <SubscriptionForm :id="subscription.id" @redirect="redirect" :email-body="subscription.email_body" :email-subject="subscription.email_subject" class="mb-5"></SubscriptionForm>
    </b-row>
  </b-container>
</template>

<script>
import Api from '@/axios/api.instance'
import SubscriptionForm from "../../../components/SubscriptionForm";

export default {
  name: "Subscriptions",
  components: {SubscriptionForm},
  data () {
    return {
      subscriptions: [],
      totalSubscriptions: 1
    }
  },
  methods: {
    redirect (subscriptionId) {
      this.$router.push('/finish-subscription/' + subscriptionId)
    }
  },
  beforeMount() {
    Api.get('/subscriptions?active=1').then(response => {
      this.subscriptions = response.data._embedded.subscriptions
      this.totalSubscriptions = response.data.total_items
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
