<template>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <h1>Studieoriënteringsdossier (SOD)</h1>
        <p>Zonder het SOD is je inschrijving niet geldig. Voor het SOD gebruik je het daarvoor voorziene formulier.<br />Na het invullen van het formulier kan je in uw account het formulier met bijhorende documenten opladen bij uw aanvraag.<br /><strong>Uw inschrijving is pas volledig wanneer het studieoriënteringsdossier met alle bijlagen uiterlijk op 30 {{ currentMonth }} {{ currentYear }} is ontvangen.</strong></p>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <a :href="publicPath + 'pat-formulier.docx'" target="_blank" class="btn btn-primary">Download het formulier</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "FinishSubscription",
  data () {
    return {
      publicPath: process.env.BASE_URL,
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().toLocaleString('default', { month: 'long' }).toLocaleLowerCase('default')
    }
  }
}
</script>

<style scoped>
.text-right {
  text-align: right;
}
</style>
