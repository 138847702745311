import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/modules/error/PageNotFound'
import Home from '@/views/modules/Home'
import InfoSessions from "../views/modules/info-sessions/InfoSessions"
import account from "./account"
import Unauthorized from "../views/modules/error/Unauthorized";
import AuthToken from "../modules/AuthToken";
import store from '@/store'
import Subscriptions from "../views/modules/subscriptions/Subscriptions";
import Subscription from "../views/modules/subscriptions/Subscription";
import FinishSubscription from "../views/modules/subscriptions/FinishSubscription";

const authTokenService = new AuthToken()

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      pageTitle: 'Home'
    }
  },
  {
    path: '/infosessies',
    name: 'InfoSessions',
    component: InfoSessions,
    meta: {
      pageTitle: 'Infosessies'
    }
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: {
      pageTitle: 'Procedures'
    }
  },
  {
    path: '/subscription/:id',
    name: 'Subscription',
    component: Subscription,
    meta: {
      pageTitle: 'Procedure',
      isAuthenticated: true
    },
  },
  {
    path: '/finish-subscription/:id',
    name: 'FinishSubscription',
    component: FinishSubscription,
    meta: {
      pageTitle: 'Procedure afwerken',
      isAuthenticated: false
    },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      pageTitle: '404'
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: {
      pageTitle: '404'
    }
  },
    account
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== 'unauthorized') {
    authTokenService.get(true).then((res) => {
      if (to.matched.some(record => record.meta.isAuthenticated)) {
        if (!res) {
          next({
            path: '/unauthorized',
            params: { nextUrl: to.fullPath }
          })
        } else {
          store.dispatch('fetch').then(() => {
            const userGrants = store.getters.grants

            if (to.meta.grants !== undefined) {
              let granted = false

              for (let i = 0; i < to.meta.grants.length; i++) {
                if (userGrants.indexOf(to.meta.grants[i]) > -1) {
                  granted = true
                }
              }

              if (!granted) {
                next({
                  path: '/unauthorized',
                  params: { nextUrl: to.fullPath }
                })
              }
            }

            next()
          }).catch((err) => {
            console.error(err)
          })
        }
      } else {
        store.dispatch('fetch').then(() => {
          next()
        }).catch((err) => {
          console.error(err)
        })
      }
    }).catch(() => {
      next({
        path: '/unauthorized',
        params: { nextUrl: to.fullPath }
      })
    })
  }
})

export default router
