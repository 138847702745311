<template>
  <b-container class="mt-5">
    <b-row>
      <b-col md="4" offset-md="4">
        <b-form @submit="onSubmit" @reset="onReset" role="form">
          <b-form-group
          >
            <b-form-input
                id="first_name"
                type="text"
                v-model="form.firstName"
                placeholder="Voornaam"
                :state="formValidation.firstName"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.firstName">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="mt-3"
          >
            <b-form-input
                id="name"
                type="text"
                v-model="form.name"
                placeholder="Naam"
                :state="formValidation.name"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.name">
              Verplicht in te vullen
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="mt-3"
          >
            <b-form-input
                placeholder="email"
                id="email"
                type="email"
                v-model="form.email"
                :state="formValidation.email"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.email">
              Verplicht in te vullen
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="formValidation.emailExist" v-if="formValidation.emailExist === false">
              Het opgegeven emailadres is al gekoppeld aan een account
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="mt-3">
            <b-button type="submit" class="mr-5" variant="primary">Opslaan</b-button>
            <b-button type="reset" variant="danger">Annuleren</b-button>
          </div>


          <transition name="fade">
            <b-row v-if="showError" class="mt-3">
              <b-col>
                <b-alert variant="danger" show>Er is een fout opgetreden, probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>

          <transition name="fade">
            <b-row v-if="showSuccess" class="mt-3">
              <b-col>
                <b-alert variant="success" show>Uw gegevens zijn succesvol aangepast.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@/axios/api.instance'

export default {
  name: 'Information',
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      emailExist: false,
      showSuccess: false,
      showError: false,
      user: {},
      form: {
        firstName: '',
        name: '',
        email: ''
      },
      formValidation: {
        firstName: null,
        name: null,
        email: null,
        emailExist: null
      }
    }
  },
  mounted () {
    this.form.firstName = this.$store.getters.firstName
    this.form.name = this.$store.getters.lastName
    this.form.email = this.$store.getters.email
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.showSuccess = false
      this.resetErrors()

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.formValidation.firstName = this.form.firstName !== ''
      this.formValidation.name = this.form.name !== ''
      this.formValidation.email = re.test(this.form.email)

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        Api.get('/local/users?uuid=' + this.$store.getters.uuid).then(response => {
          Api.put('/local/users/' + response.data.id, {
            first_name: this.form.firstName,
            last_name: this.form.name,
            email: this.form.email
          }).then(() => {
            this.showSuccess = true
            this.formValidation.emailExist = true

            setTimeout(() => {
              this.showSuccess = false
            }, 5000)
          }).catch(err => {
            this.showError = true
            if (err.response.data.validation_messages.email.length > 0) {
              this.formValidation.emailExist = false
            } else {
              this.formValidation.emailExist = true
            }
          })
        }).catch(err => {
          console.log(err)
        })
      }
    },
    onReset (event) {
      if (event !== undefined) {
        event.preventDefault()
      }
      this.form.firstName = this.$store.getters.firstName
      this.form.name = this.$store.getters.lastName
      this.form.email = this.$store.getters.email
      this.resetErrors()
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>
.mr-5 {
  margin-right:20px;
}
</style>
