<template>
  <b-container class="mt-5">
    <b-row>
      <b-col md="4" offset-md="4">
        <b-form @submit="onSubmit" inline>
          <b-form-group
              id="email"
              label-for="email"
          >
            <b-form-input
                id="email"
                v-model="form.email"
                type="text"
                placeholder="Uw emailadres"
            ></b-form-input>
          </b-form-group>
          <b-button block variant="primary" type="submit" class="mt-3">Wachtwoord aanvragen</b-button><br />
          <router-link to="/account/login" class="text-center btn-block link"><small>Login?</small></router-link>
          <b-alert :show="showError" variant="danger" class="mt-2">Er is iets fout gegaan bij het aanvragen van een nieuw wachtwoord. Controleer uw email adres en probeer opnieuw.</b-alert>
          <b-alert :show="showSuccess" variant="success" class="mt-2">Er word een nieuw wachtwoord verzonden naar uw opgegeven emailadres.</b-alert>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Mailer from "../../../mixins/Mailer";
import Api from '@/axios/api.instance'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: "ForgotPassword",
  mixins: [Mailer],
  data () {
    return {
      showError: false,
      showSuccess: false,
      form: {
        email: ''
      }
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()

      this.showError = false
      this.showSuccess = false

      const password = Math.random().toString(36).substring(2, 10)

      Api.post('/local/forgot-password', {
        email: this.form.email,
        password: password
      }).then(async () => {
        Api.get('/mailing/emails/' + process.env.VUE_APP_STUDENT_PASSWORD_EMAIL).then(async response => {
          const email = await this.composeEmail( [
            {
              needle: 'password',
              replacer: password
            }], response.data.content)

          await this.sendMail(this.form.email, this.form.email, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, response.data.subject, email.body)
          this.showSuccess = true
          this.form.email = ''
          authToken.clear()
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
        this.showError = true
      })
    }
  }
}
</script>

<style scoped>

</style>
