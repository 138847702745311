import Gateway from '../axios/gateway.instance'

class AuthToken {
  store (tokenData, clientToken) {
    return new Promise((resolve) => {
      const d = new Date(Date.now())
      d.setSeconds(d.getSeconds() + tokenData.expires_in)
      tokenData.expiryDate = d

      const tokenStorageName = (clientToken === undefined) ? 'authToken' : 'clientToken'
      localStorage.setItem(tokenStorageName, JSON.stringify(tokenData))
      resolve(tokenData)
    })
  }

  async get (clientToken) {
    const tokenStorageName = (clientToken === undefined) ? 'authToken' : 'clientToken'
    const tokenData = JSON.parse(localStorage.getItem(tokenStorageName))

    return new Promise((resolve) => {
      if (clientToken !== undefined) {
        if (tokenData !== null && tokenData !== undefined) {
          const currentDate = new Date(Date.now())
          let dateCheck = false
          const tokenDate = new Date(tokenData.expiryDate)
          dateCheck = tokenDate > currentDate

          if (!dateCheck) {
            resolve(Gateway.post('oauth', {
              grant_type: 'refresh_token',
              refresh_token: tokenData.refresh_token
            }).then(async (data) => {
              return await this.store(data.data, true)
            }).catch((error) => {
              console.log(error)
            }))
          }

          resolve(tokenData)
        } else {
          resolve(null)
        }

        resolve(false)
      } else {
        if (tokenData !== null && tokenData !== undefined) {
          const currentDate = new Date(Date.now())
          let dateCheck = false
          const tokenDate = new Date(tokenData.expiryDate)
          dateCheck = tokenDate > currentDate

          if (!dateCheck) {
            resolve(Gateway.post('oauth', {
              grant_type: 'client_credentials'
            }).then(async (data) => {
              return await this.store(data.data)
            }).catch((error) => {
              console.log(error)
            }))
          }

          resolve(tokenData)
        } else {
          resolve(Gateway.post('oauth', {
            grant_type: 'client_credentials'
          }).then(async (data) => {
            return this.store(data.data);
          }).catch((error) => {
            console.log(error)
          }))
        }

        resolve(false)
      }
    })
  }

  clear (clientToken) {
    const tokenStorageName = (clientToken === undefined) ? 'authToken' : 'clientToken'
    localStorage.removeItem(tokenStorageName)
  }
}

export default AuthToken
